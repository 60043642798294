import React from 'react'
import MediaQuery from 'react-responsive'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import HubspotForm from 'react-hubspot-form'
import Modal from 'react-responsive-modal'
import Layout from '../../components/layout'

import {
	Container,
	Section,
	Row,
	Column,
} from '../../components/layouts'

import {
	Title,
	Subtitle,
	Paragraph,
	Button,
	Image,
	BetaLabel,
	Input,
	Card,
	List,
	Testimonial,
	Link,
	BtnField
} from '../../components/ui'

import theme from '../../theme/theme'
import { getUrlParams } from '../../util'
import {newOrgSignUp} from '../../factory'

const {
	introNonprofit,
	beOnline,
	forwardThinking,
	contactSales,
	testimonials,
	fundraising,
	intro,
	callToAction,
	retarget,
	securityMatters
} = theme.sections

const moneyFormat = (num) => {
	return num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.scrolledElement = null

		this.state = {
			animateCards: false,
			contactFormSubmitted: false,
			showSignUpModal: false,
			open: true,
			totalSupporters: 5000,
			downloads: 50,
			accounts: 20,
			fname: '',
			lname: '',
			email: '',
			organization_title: '',
			organization_website: ''
		}

		this.handleScroll = this.handleScroll.bind(this)
		this.smoothScroll = this.smoothScroll.bind(this)
		this.onOpenModal = this.onOpenModal.bind(this)
		this.onCloseModal = this.onCloseModal.bind(this)
		this.onFunnelChange = this.onFunnelChange.bind(this)
		this.handleContactFormEdit = this.handleContactFormEdit.bind(this)
		this.submitContactForm = this.submitContactForm.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)

		const thankYouModal = getUrlParams().thankyou
		const GUID = getUrlParams().submissionGuid
		if (thankYouModal) {
			try {
				/* eslint-disable-next-line */
				analytics.track('Lead Form Submitted', {
					submissionGUID: GUID,
				})
			} catch (err) {
				// ignore
			}
		}

		const ebookModal = getUrlParams().ebook
		if (ebookModal) {
			try {
				/* eslint-disable-next-line */
				analytics.track('EBook Form Submitted', {
					submissionGUID: GUID,
				})
			} catch (err) {
				//
			}
		}

		this.smoothScroll()
	}

	componentWillUnmount() {
		typeof window !== 'undefined' && window.removeEventListener('scroll', this.handleScroll)
	}

	onOpenModal() {
		this.setState({ open: true })
	}

	onCloseModal() {
		this.setState({ open: false })
	}

	onFunnelChange(e) {
		this.setState({ [e.target.name]: e.target.value })
	}

	handleScroll() {
		if (document.getElementById('cardsContainer')) {
			this.scrolledElement = document.getElementById('cardsContainer')

			if ((this.scrolledElement.offsetTop - (window.innerHeight / 2)) < window.scrollY) {
				this.setState({
					animateCards: true
				})
			}
		}
	}

	smoothScroll(id) {
		if (window.location.href.includes('#') || id) {
			const className = id ? id : "#" + window.location.href.split('#')[1]
			try{
				const element = document.querySelector(className)
				element.scrollIntoView({
					behavior: 'smooth'
				})
			} catch{
				// do nothing
			}
		}
	}

	submitContactForm(e) {
		e.preventDefault()
		e.stopPropagation()

		const { email, fname, lname, organization_title, organization_website } = this.state

		const slackBody = {email, fname, lname, organization_title, organization_website}
		const slackText = "New Org Sign up! \n" + JSON.stringify(slackBody).replace(/"/g, "'")

		try {
			/* eslint-disable-next-line */
			analytics.identify(slackBody)

			/* eslint-disable-next-line */
			analytics.track('New Org Submitted')
		} catch (err) {
			// ignore
		}


		this.props.sendSlack(slackText)

		this.setState({ fname: '', lname: '', email: '', organization_title: '', organization_website: '', formSubmitted: true })
	}

	handleContactFormEdit(e) {
		this.setState({ [e.target.name]: e.target.value })
	}

	render() {
		const { showSignUpModal } = this.state
		const thankYouModal = getUrlParams().thankyou
		const ebookModal = getUrlParams().ebook
		const totalUsers = Math.round(this.state.totalSupporters * this.state.downloads * this.state.accounts / 10000)
		const monthlyDonations = totalUsers * 25
		const annualDonations = monthlyDonations * 12

		return (
			<Layout>
				<div style={{ paddingLeft: 20, paddingRight: 20 }}>
					{thankYouModal &&
				<Modal
					open={this.state.open} onClose={this.onCloseModal} center>
					<div style={{ maxWidth: '90%' }}>
						<Title fontSize={'50px'}>
					You are awesome!
						</Title>
						<Paragraph
							fontSize={'21px'}
							style={{ maxWidth: '100%' }}
							fontWeight={'200'}>Someone from our team will be in contact in the next
						few days to learn more about your organization and how we can help!</Paragraph>
					</div>
				</Modal>
					}

					{showSignUpModal && !thankYouModal && !ebookModal &&
						<Modal
							open={this.state.open} onClose={this.onCloseModal} center>
							<div style={{ maxWidth: '90%' }}>
								<Title fontSize={'30px'}>
								Looking to improve your fundraising?
								</Title>
								<Paragraph
									fontSize={'21px'}
									marginRight={"2em"}
									fontWeight={'200'}>Fill out this form to receive our free guide <span style={{ fontStyle: 'italic' }}>Millennials To Donors</span></Paragraph>
								<HubspotForm
									id="reactHubspotForm"
									portalId="4829666"
									formId="1c650ab1-5fd3-4250-88c3-269180d71e88"
									onSubmit={() => this.setState({ contactFormSubmitted: true })}
									loading={<div>{'Loading...'}</div>}
								/>
							</div>
						</Modal>
					}

					{ebookModal &&
				<Modal
					open={this.state.open} onClose={this.onCloseModal} center>
					<Title fontSize={'50px'}>
					You are awesome!
					</Title>
					<Paragraph
						fontSize={'21px'}
						fontWeight={'200'}>An email is on its way with our guide to attracting and cultivating millennials to your organization. We look forward to hearing about your success!</Paragraph>
				</Modal>
					}
				</div>
				{/* Intro section */}
				<Section id="intro" bg={introNonprofit.bg} paddingTop={'72px'} smallPaddingTop={'8em'} paddingBottom={'0'}>
					<Container>
						{/* Intro first section */}
						<Row marginBottom={'2em'} smallMarginBottom={'1em'}>
							<Column vAlign={'center'} maxWidth={'50%'}>
								<Title fontSize={'50px'}>{introNonprofit.content.firstPart.title}</Title>
								<Row>
									<Column row>
										<Button
											href={introNonprofit.content.firstPart.firstBtn.link}
											bgColor={'white'}
											hoverBg={theme.colors.sunglow}
											hoverTextColor={'white'}
											marginRight={'1.5em'}
											smallMarginRight={'1em'}
											smallMarginBottom={'1em'}
											maxWidth={'250px'}
											style={{'border': `3px solid ${theme.colors.sunglow}`}}>
											{introNonprofit.content.firstPart.firstBtn.text}</Button>
									</Column>
								</Row>
							</Column>
							<Column maxWidth={'50%'}>
								<MediaQuery query={`(max-width: ${theme.breakPoints.small}) and (min-width: ${theme.breakPoints.xsmall})`}>
									<div>
										<br />
									</div>
								</MediaQuery>
								<MediaQuery minWidth={theme.breakPoints.xsmall}>
									<Image src={introNonprofit.content.firstPart.image} marginTop={'2em'} />
								</MediaQuery>
							</Column>
						</Row>
					</Container>
				</Section>
				<Section >
					<Container>
						<MediaQuery maxWidth={theme.breakPoints.small}>
							{/* Intro second section */}
							<Row marginBottom={'4em'}>
								<Column maxWidth={'50%'}>
									<Image src={introNonprofit.content.secondPart.image} />
								</Column>
								<Column maxWidth={'50%'}>
									<Title fontSize={'33px'} marginBottom={'1.5em'} marginLeft={'0'}>{introNonprofit.content.secondPart.title}</Title>
									<List list={introNonprofit.content.secondPart.list} />
								</Column>
							</Row>
						</MediaQuery>
						<MediaQuery minWidth={theme.breakPoints.small}>
							{/* Intro second section */}
							<Title fontSize={'45px'}style={{width: '80%'}} marginLeft={'0'}>{introNonprofit.content.secondPart.title}</Title>
							<Image src={introNonprofit.content.secondPart.image2} style={{marginBottom: '6em'}}/>
						</MediaQuery>
					</Container>
				</Section>
				{/* Be online section */}
				<Section bg={beOnline.bg} paddingTop={'3em'} paddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{beOnline.content.title}</Title>
								<Paragraph color={theme.colors.white} maxWidth={'80%'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2em'} fontWeight={'200'}>{beOnline.content.paragraph}</Paragraph>
								<Row>
									<Column>
										<Row>
											<MediaQuery maxWidth={theme.breakPoints.small}>
												<Image src={beOnline.content.image1} width={"100%"} />
												<Paragraph style={{fontSize: 18, borderBottom: `5px solid white`, textAlign: 'center', width: '100%'}}>
													Using <span style={{fontWeight: 600}}>One-Click Relationships,</span> our clients are able to have  personalized, automated conversations with their donors, increasing engagement, and ultimately, driving more donations
												</Paragraph>
											</MediaQuery>
											<MediaQuery minWidth={theme.breakPoints.small}>
												<Image src={beOnline.content.image1} width={"50%"} style={{borderBottom: `5px solid white`, marginBottom: 18}}/>
												<Paragraph style={{width: 200, fontSize: 18, borderBottom: `5px solid white`, }}>
												Using <span style={{fontWeight: 600}}>One-Click Relationships,</span> our clients are able to have  personalized, automated conversations with their donors, increasing engagement, and ultimately, driving more donations
												</Paragraph>
											</MediaQuery>
										</Row>
									</Column>
									<Column style={{ marginTop: '6em', alignItems: 'flex-end'}}>
										<Row>
											<MediaQuery maxWidth={theme.breakPoints.small}>
												<Image src={beOnline.content.image2} width={"100%"} />
												<Paragraph style={{fontSize: 18, borderBottom: `5px solid white`, textAlign: 'center', width: '100%'}}>
												Using <span style={{fontWeight: 600}}>proprietary marketing algorithms,</span> our organizations are able to accurately and effectively target current and potential donors, resulting in greater interest and larger donations
												</Paragraph>
											</MediaQuery>
											<MediaQuery minWidth={theme.breakPoints.small}>
												<Paragraph style={{width: 200, fontSize: 18, borderBottom: `5px solid white`, }}>
												Using <span style={{fontWeight: 600}}>proprietary marketing algorithms,</span> our organizations are able to accurately and effectively target current and potential donors, resulting in greater interest and larger donations
												</Paragraph>
												<Image src={beOnline.content.image2} width={"50%"} style={{borderBottom: `5px solid white`, marginBottom: 18}}/>
											</MediaQuery>
										</Row>
									</Column>
								</Row>
							</Column>
						</Row>
						<Row style={{background: 'white', border: `5px solid ${theme.colors.sunglow}`, justifyContent: 'center', alignItems: 'center'}}>
							<Title as="h2" fontSize={'30px'} style={{margin: 0, marginRight: 15}}>Ready to jump in?</Title>
							<Button
								href={introNonprofit.content.firstPart.firstBtn.link}
								bgColor={'white'}
								hoverBg={theme.colors.sunglow}
								hoverTextColor={'white'}
								marginLeft={'1.5em'}
								smallMarginRight={'1em'}
								marginBottom={'0em'}
								maxWidth={'250px'}
								style={{'border': `3px solid ${theme.colors.sunglow}`, 'margin': '2em 0'}}>
								{introNonprofit.content.firstPart.firstBtn.text}</Button>
						</Row>
					</Container>
				</Section>
				{/* Forward Thinking section */}
				<Section id="cardsContainer" bg={forwardThinking.bg} paddingTop={'3em'} paddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{forwardThinking.content.title}</Title>
								<Paragraph color={theme.colors.white} maxWidth={'420px'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>{forwardThinking.content.paragraph}</Paragraph>
							</Column>
						</Row>
						<Row>
							<Column maxWidth={'50%'}>
								<Card style={{display: 'flex', padding: 20}} animatedLeft className={this.state.animateCards ? 'animate' : false}>
									<MediaQuery minWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'} style={{maxWidth: '30%'}}>
												<Image src={forwardThinking.features.first.icon} marginBottom={'0'} style={{maxWidth: 92, height: 'auto', width: 'auto'}} />
											</Column>
											<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.first.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.first.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
									<MediaQuery maxWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.first.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.first.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>

								</Card>
								<Card style={{display: 'flex', padding: 20}} animatedLeft className={this.state.animateCards ? 'animate' : false}>
									<MediaQuery minWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'} style={{maxWidth: '30%'}}>
												<Image width={'100%'} src={forwardThinking.features.second.icon} marginBottom={'0'} style={{maxWidth: 92, height: 'auto', width: 'auto'}} />
											</Column>
											<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.second.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.second.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
									<MediaQuery maxWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.second.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.second.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
								</Card>
							</Column>
							<Column maxWidth={'50%'}>
								<Card style={{display: 'flex', padding: 20}} animatedRight className={this.state.animateCards ? 'animate' : false}>
									{forwardThinking.features.fourth.betaLabel && <BetaLabel src={forwardThinking.features.fourth.betaLabel} left={'auto'} right={'0'} top={'0'} marginLeft={'auto'} marginRight={'auto'} maxWidth={'70px'} />}
									<MediaQuery minWidth={theme.breakPoints.small} style={{flex: 1}}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'} style={{maxWidth: '30%', flex: 1}}>
												<Image width={'100%'} src={forwardThinking.features.third.icon} marginBottom={'0'} style={{maxWidth: 92, height: 'auto', width: 'auto'}} />
											</Column>
											<Column style={{display: 'flex', padding: 5, flex: 5}} vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.third.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.third.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
									<MediaQuery maxWidth={theme.breakPoints.small} style={{flex: 1}}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column style={{display: 'flex', padding: 5, flex: 5}} vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.third.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.third.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
								</Card>
								<Card style={{display: 'flex', padding: 20}} animatedRight className={this.state.animateCards ? 'animate' : false}>
									<MediaQuery minWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column vAlign={'center'} maxWidth={'30%'} smallMaxWidth={'25%'} xsmallMaxWidth={'25%'} style={{maxWidth: '30%'}}>
												<Image width={'100%'} src={forwardThinking.features.fourth.icon} marginBottom={'0'} style={{maxWidth: 92, height: 'auto', width: 'auto'}} />
											</Column>
											<Column style={{display: 'flex', padding: 5}}  vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.fourth.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.fourth.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
									<MediaQuery maxWidth={theme.breakPoints.small}>
										<Row marginBottom={'0'} xsmallNoColumn>
											<Column style={{display: 'flex', padding: 5}}  vAlign={'center'} maxWidth={'70%'} smallMaxWidth={'100%'} xsmallMaxWidth={'100%'}>
												<Subtitle fontFamily={theme.fonts.primary} fontSize={'18px'} marginLeft={'0'} marginBottom={'1em'}>{forwardThinking.features.fourth.title}</Subtitle>
												<Paragraph fontSize={'14px'}>{forwardThinking.features.fourth.paragraph}</Paragraph>
											</Column>
										</Row>
									</MediaQuery>
								</Card>
							</Column>
						</Row>
						<Row style={{background: 'white', border: `5px solid ${theme.colors.sunglow}`, justifyContent: 'center', alignItems: 'center'}}>
							<Title as="h2" fontSize={'30px'} style={{margin: 0, marginRight: 15}}>Excited to try Flourish?</Title>
							<Button
								href={introNonprofit.content.firstPart.firstBtn.link}
								bgColor={'white'}
								hoverBg={theme.colors.sunglow}
								hoverTextColor={'white'}
								marginLeft={'1.5em'}
								smallMarginRight={'1em'}
								maxWidth={'250px'}
								style={{'border': `3px solid ${theme.colors.sunglow}`, 'margin': '2em 0'}}>
								{introNonprofit.content.firstPart.firstBtn.text}</Button>
						</Row>
					</Container>
				</Section>
				{/* testimonials */}
				<Section bg={testimonials.bg} paddingTop={'5em'} paddingBottom={'5em'} smallPaddingTop={'4em'} smallPaddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{testimonials.content.title}</Title>
								<Paragraph color={theme.colors.white} maxWidth={'80%'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2em'} fontWeight={'200'}>{testimonials.content.paragraph}</Paragraph>
								<Row>
									{testimonials.content.quotes.map((test, i) =>
										(<Column key={i} vAlign={'center'} row>
											<Testimonial data={test}/>
										</Column>)
									)}
								</Row>
							</Column>
						</Row>
					</Container>
				</Section>
				{/* Flourish App */}
				<Section paddingTop={'5em'} paddingBottom={'5em'} smallPaddingTop={'4em'} smallPaddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{fundraising.content.title}</Title>
								<Paragraph maxWidth={'80%'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2em'} fontWeight={'200'}>{fundraising.content.paragraph}</Paragraph>
								<Column>
									<Row>
										<Column style={{justifyContent: 'center'}}>
											<Title fontSize={'25px'} textAlign={'center'} marginBottom={'0.5em'}>{fundraising.content.listTitle}</Title>
											<List list={fundraising.content.list} />

											<Title fontSize={'25px'} textAlign={'center'} marginBottom={'0.5em'} marginTop={'1em'}>Secured by the best</Title>
											<Paragraph maxWidth={'706px'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'0.5em'} fontWeight={'200'}>{securityMatters.content.paragraph}</Paragraph>
											<Button href={securityMatters.content.btn.link} hoverBg={theme.colors.salomie} hoverTextColor={theme.colors.black} marginLeft={'auto'} marginRight={'auto'} smallMarginLeft={'auto'} smallMarginRight={'auto'} maxWidth={'200px'}>{securityMatters.content.btn.text}</Button>

										</Column>
										<Column>
											<Image src={intro.content.firstPart.image} marginTop={'2em'} />
										</Column>
									</Row>
									<Row>
										<MediaQuery minWidth={theme.breakPoints.small}>
											<Row marginBottom={'0'} style={{marginTop: 25}}>
												<Column center >
													<Link href={callToAction.content.appStore.link} maxWidth={'144px'} marginRight={'auto'}>
														<Image src={callToAction.content.appStore.image} marginRight={'auto'} marginBottom={'0'} height={'48px'} maxWidth={'144px'} />
													</Link>
												</Column>
												<Column center >
													<Link href={callToAction.content.googlePlay.link} maxWidth={'162px'} marginRight={'auto'}>
														<Image src={callToAction.content.googlePlay.image} marginBottom={'0'} height={'48px'} maxWidth={'162px'} />
													</Link>
												</Column>
											</Row>
										</MediaQuery>
									</Row>
									<Row style={{background: 'white', border: `5px solid ${theme.colors.sunglow}`, justifyContent: 'center', alignItems: 'center'}}>
										<Title as="h2" fontSize={'30px'} style={{margin: 0, marginRight: 15}}>Excited to get started?</Title>

										<Button
											href={introNonprofit.content.firstPart.firstBtn.link}
											bgColor={'white'}
											hoverBg={theme.colors.sunglow}
											hoverTextColor={'white'}
											marginLeft={'1.5em'}
											smallMarginRight={'1em'}
											maxWidth={'250px'}
											style={{'border': `3px solid ${theme.colors.sunglow}`, margin: '2em 0' }}>
											{introNonprofit.content.firstPart.firstBtn.text}</Button>
									</Row>
								</Column>
								<MediaQuery maxWidth={theme.breakPoints.small}>
									<Row marginBottom={'0'} style={{jusifyContent: 'center', alignItems: 'center'}}>
										<Column width={'100%'} style={{jusifyContent: 'center', alignItems: 'center'}}>
											<Link href={callToAction.content.appStore.link} marginRight={'auto'} style={{jusifyContent: 'center', alignItems: 'center', padding: 'auto', display: 'flex', marginBottom: '1.5em'}}>
												<Image src={callToAction.content.appStore.image} marginBottom={'0'} height={'48px'} maxWidth={'144px'} smallWidth={'none'} style={{margin: 'auto'}}/>
											</Link>
											<Link href={callToAction.content.googlePlay.link} marginRight={'auto'} style={{jusifyContent: 'center', alignItems: 'center', padding: 'auto', display: 'flex', marginBottom: '1.5em'}}>
												<Image src={callToAction.content.googlePlay.image} marginBottom={'0'} height={'48px'} maxWidth={'162px'} style={{margin: 'auto'}}/>
											</Link>
										</Column>
									</Row>
								</MediaQuery>
							</Column>
						</Row>
					</Container>
					<Container>
						<Row>
							<Column>
								<Title fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>Just {(totalUsers / this.state.totalSupporters * 100).toFixed(2)}% of your donors...</Title>
								<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>Thousands of dollars, every month.</Paragraph>
							</Column>
						</Row>
						<Row>
							<Column vAlign={'center'} maxWidth={'50%'} row>
								<Card style={{ justifyContent: 'center', height: 100, backgroundColor: theme.colors.sunglow, width: '50%' }}  marginRight={'0'}>
									<Title color={"white"} fontSize={'26px'} textAlign={'center'} marginTop={'1em'} marginBottom={'0.5em'}>${moneyFormat(monthlyDonations)}<br/>
										<Paragraph>Exp. Monthly Donations</Paragraph></Title>
								</Card>
							</Column>
							<Column vAlign={'center'} maxWidth={'50%'} row padding={50}>
								<Card style={{ justifyContent: 'center', height: 100, backgroundColor: theme.colors.crusta, width: '50%' }}  marginRight={'0'}>
									<Title color={"white"} fontSize={'26px'} textAlign={'center'} marginTop={'1em'} marginBottom={'0.5em'}>${moneyFormat(annualDonations)}<br/>
										<Paragraph>Exp. Yearly Donations</Paragraph></Title>
								</Card>
							</Column>
							<MediaQuery maxWidth={theme.breakPoints.small}>
							*Based on an estimate of {this.state.totalSupporters} Flourish donors.
							</MediaQuery>
						</Row>
						<MediaQuery minWidth={theme.breakPoints.small}>
							<Row>
								<Column vAlign={'center'} maxWidth={'25%'} height={'350px'} row>
									<Section bg={{
										image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Website/Graph/TotalSupporters.png',
										size: '225px 350px',
										position: 'bottom'
									}}
									paddingTop={'175px'}
									>
										<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'0'} fontWeight={'600'}>{this.state.totalSupporters.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Paragraph>
										<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'2.5em'} fontWeight={'400'}>total supporters reached</Paragraph>
									</Section>
								</Column>
								<Column vAlign={'center'} maxWidth={'25%'} height={'350px'} row>
									<Section bg={{
										image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Website/Graph/downloads.png',
										size: '225px 230px',
										position: 'bottom'
									}}
									paddingTop={'225px'}
									>
										<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'0'} fontWeight={'600'}>{this.state.downloads}%</Paragraph>
										<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'2.5em'} fontWeight={'400'}>% who download</Paragraph>
									</Section>
								</Column>
								<Column vAlign={'center'} maxWidth={'25%'} height={'350px'} row>
									<Section bg={{
										image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Website/Graph/accounts.png',
										size: '225px 150px',
										position: 'bottom'
									}}
									paddingTop={'240px'}
									>
										<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'0'} fontWeight={'600'}>{this.state.accounts}%</Paragraph>
										<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'50px'} marginRight={'50px'} marginBottom={'2em'} fontWeight={'400'}>% accounts created</Paragraph>
									</Section>
								</Column>
								<Column vAlign={'center'} maxWidth={'25%'} height={'350px'} row>
									<Section bg={{
										image: 'https://flourishfiles.blob.core.windows.net/files/Marketing/Website/Graph/avgDonation.png',
										size: '225px 100px',
										position: 'bottom'
									}}
									paddingTop={'290px'}
									>
										<Paragraph maxWidth={'200px'} fontSize={'16px'} textAlign={'center'} marginLeft={'100px'} marginRight={'100px'} marginBottom={'0'} fontWeight={'600'}>{totalUsers}</Paragraph>
										<Paragraph maxWidth={'225px'} fontSize={'16px'} textAlign={'center'} marginLeft={'25px'} marginRight={'25px'} marginBottom={'0'} fontWeight={'400'}>Flourish donors</Paragraph>
									</Section>
								</Column>
							</Row>
							<Row id="funnel">
								<Column center vAlign={'center'} maxWidth={'22.75%'} row>
									<Input name="totalSupporters" onChange={this.onFunnelChange} type="number" value={this.state.totalSupporters} minLength="2" shadow autoComplete="off" required="required" />
									<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>Total # Supporters</Paragraph>
								</Column>
								<Column vAlign={'center'} maxWidth={'3%'} row>
									<Paragraph fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>X</Paragraph>
								</Column>
								<Column vAlign={'center'} maxWidth={'22.75%'} row>
									<Input name="downloads" onChange={this.onFunnelChange} type="number" value={this.state.downloads} maxLength="3" shadow autoComplete="off" required="required" />
									<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>% who download Flourish</Paragraph>
								</Column>
								<Column vAlign={'center'} maxWidth={'3%'} row>
									<Paragraph fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>X</Paragraph>
								</Column>
								<Column vAlign={'center'} maxWidth={'22.75%'} row>
									<Input name="accounts" onChange={this.onFunnelChange} type="number" value={this.state.accounts} shadow autoComplete="off" required="required" />
									<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2.5em'} fontWeight={'400'}>% who create accounts</Paragraph>
								</Column>
								<Column vAlign={'center'} maxWidth={'3%'} row>
									<Paragraph fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>X</Paragraph>
								</Column>
								<Column vAlign={'center'} maxWidth={'22.75%'} paddingTop={'1em'} row>
									<Paragraph maxWidth={'100%'} fontSize={'16px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} fontWeight={'400'}>avg. $25/month donated </Paragraph>
								</Column>
							</Row>
						</MediaQuery>
					</Container>
				</Section>

				{/* Retarget */}
				<Section bg={retarget.bg} paddingTop={'5em'} paddingBottom={'5em'} smallPaddingTop={'4em'} smallPaddingBottom={'3em'}>
					<Container>
						<Row>
							<Column>
								<Title color={theme.colors.white} fontSize={'33px'} textAlign={'center'} marginBottom={'0.5em'}>{retarget.content.title}</Title>
								<Paragraph color={theme.colors.white} maxWidth={'80%'} fontSize={'21px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2em'} fontWeight={'200'}>{retarget.content.paragraph}</Paragraph>
								<Paragraph maxWidth={'100%'} fontSize={'25px'} textAlign={'center'} marginLeft={'auto'} marginRight={'auto'} marginBottom={'2em'} fontWeight={'200'}>{retarget.content.subtext}</Paragraph>
								<MediaQuery minWidth={theme.breakPoints.small}>
									<Image src={introNonprofit.content.secondPart.image2} style={{marginBottom: '6em'}}/>
								</MediaQuery>
								<Button href={introNonprofit.content.firstPart.firstBtn.link} style={{width: 300, margin: 'auto', marginBottom: '1em'}}>Contact us to learn more</Button>
							</Column>
						</Row>
					</Container>
				</Section>

				{/* Form
				<Section id="signUp">
					<Row marginBottom={'0'} style={{marginLeft: 0, marginRight: 0}}>
						<Column lateralPaddings={'0'} maxWidth={'50%'}>
							<img style={{width: "100%"}} src={contactSales.content.leftBg}/>
						</Column>
						<Column lateralPaddings={'0'} style={{alignItems: 'center', justifyContent: 'center'}} >
							{!this.state.formSubmitted ?
								<Title fontSize={'33px'} textAlign={'center'} marginTop={'1em'} maxWidth={'80%'}>Sign Up Your Organization For Free</Title> :
								<Title fontSize={'33px'} textAlign={'center'} marginTop={'1em'} maxWidth={'380px'}>{contactSales.content.thanksTitle}</Title>
							}

							{this.state.formSubmitted ?
								<div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
									<Paragraph fontSize={'16px'} textAlign={'center'} marginBottom={'1.5em'}>{contactSales.content.thankyouParagraph}</Paragraph>
									<BtnField onClick={()=>this.setState({formSubmitted: false})} style={{padding: 0}} marginLeft={'auto'} marginRight={'auto'} maxWidth={'175px'} hoverBg={theme.colors.salomie}>Sign Up Again</BtnField>
								</div>
								:
								<Row align={'center'} justify={'center'} style={{marginLeft: 0, marginRight: 0}}>
									<Column style={{margin: 'auto', padding: '0 15%'}}>
										<form onSubmit={(e) => {
											this.submitContactForm(e)
										}}>
											<Row>
												<Column lateralPaddings={'10px'} maxWidth={'50%'} smallMarginBottom={'10px'}>
													<Input value={this.state.fname} name="fname" type="text" onChange={this.handleContactFormEdit} placeholder={'Braden'} minLength="4" shadow autoComplete="off" required="required" />
												</Column>
												<MediaQuery maxWidth={theme.breakPoints.xsmall}>
													<Row />
												</MediaQuery>
												<Column lateralPaddings={'10px'} maxWidth={'50%'} smallMarginBottom={'10px'}>
													<Input value={this.state.lname} name="lname" type="text" onChange={this.handleContactFormEdit} placeholder={"Smith"} minLength="4" shadow autoComplete="off" required="required" />
												</Column>
											</Row>
											<Row>
												<Column lateralPaddings={'10px'} smallMarginBottom={'10px'}>
													<Input value={this.state.email} name="email" type="email" onChange={this.handleContactFormEdit} placeholder={'braden@flourishchange.com'} shadow autoComplete="off" required="required" />
												</Column>
											</Row>
											<Row>
												<Column lateralPaddings={'10px'} smallMarginBottom={'10px'}>
													<Input value={this.state.organization_title} name="organization_title" type="text" onChange={this.handleContactFormEdit} placeholder={'The Flourish Organization'} shadow autoComplete="off" required="required" />
												</Column>
											</Row>
											<Row>
												<Column lateralPaddings={'10px'} smallMarginBottom={'10px'}>
													<Input value={this.state.organization_website} name="organization_website" type="text" onChange={this.handleContactFormEdit} placeholder={'https://www.flourishchange.com'} shadow autoComplete="off" required="required" />
												</Column>
											</Row>
											<Row>
												<Column>
													<BtnField type="submit" style={{padding: 0}} marginLeft={'auto'} marginRight={'auto'} maxWidth={'175px'} hoverBg={theme.colors.salomie}>Sign Me Up!</BtnField>
													<Paragraph fontSize={'8px'} textAlign={'center'}>By Submitting this form, you agree to add your organization to the Flourish App.
													This includes but is not limited to your logo, name, branding, and likeness as found on your website and public materials. You also agree to our <a href="https://storage.googleapis.com/resources.flourishchange.com/Marketing/Contracts/Terms_Of_Service.pdf" target="_blank">
													SaaS Terms of Service</a> and <a href="/privacy" target="_blank">Privacy Policy.</a></Paragraph>
												</Column>
											</Row>
										</form>
									</Column>
								</Row>
							}
						</Column>
					</Row>
				</Section> */}
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	sendSlack: PropTypes.func
}

// const mapStateToProps = (state) => {
// 	return {
// 		error: typeof state.donateNow.error === 'object' && state.donateNow.error ? Object.values(state.donateNow.error) : (state.donateNow.error ? state.donateNow.error : null),
// 		pending: state.donateNow.pending,
// 	}
// }

const mapDispatchToProps = (dispatch) => {
	return {
		sendSlack: (text) => dispatch(newOrgSignUp(text))
	}
}

export default connect(
	null,
	mapDispatchToProps
)(IndexPage)
